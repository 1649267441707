$(document).foundation();

$(document ).ready(function() {
	$(function() {
		$("a.no-link").click(function(){
			return false;
		});
		$('.bal-sponsor img').on('load',function() {
			$.get(this.src)
				.done(function() { 
					$('.bal-sponsor').css("visibility", "visible");	

				}).fail(function() { 
					$('.bal-sponsor').css("visibility", "hidden");	

				});
		}).each(function() {
			if(this.complete) $(this).load();
		});
	});
	// Add it after jquery.magnific-popup.js and before first initialization code
	$.extend(true, $.magnificPopup.defaults, {
		tClose: 'Afsluiten (Esc)',
		tLoading: 'Bezig met laden...',
		gallery: {
			tPrev: 'Vorige', // Alt text on left arrow
			tNext: 'Volgende', // Alt text on right arrow
			tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
		},
		image: {
			tError: '<a href="%url%">De afbeelding</a> kan niet worden geladen' // Error message when image could not be loaded
		},
		ajax: {
			tError: '<a href="%url%">De inhoud</a> kan niet worden geladen.' // Error message when ajax request failed
		}
	});
	$('.gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		closeOnContentClick: true,
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		}
	});


});